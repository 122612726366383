// Category navigation
$(document).on('click', '[data-page-category]', function (e) {
    e.preventDefault();

    var title = $(this).text();

    $.ajax({
        url: '/ajax-page-category',
        type: 'GET',
        data: { category_id: $(this).data('page-category'), lang_id: $('html').attr('lang') }
    }).done(function (result) {
        var items = JSON.parse(result);
        var menu = $('#ajax-nav-bottom');
        var heading = menu.find('h1');
        $('#ajax-nav-top').hide();
        $('#ajax-home').show();

        if (heading.length) {
            heading.show().text(title);
        } else {
            menu.find('ul').before('<h1 class="menu-container__title menu-container__title--mobile">' + title + '</h1>');
        }

        menu.hide();
        menu.find('ul li').remove();

        $.each(items, function (index, elem) {
            menu.find('ul').append('<li><a href="' + elem.url + '">' + elem.title + '</a></li>');
        });

        menu.fadeIn(250);
    });
});

$(document).on('click', '[data-reference-category]', function (e) {
    e.preventDefault();

    var title = $(this).text();

    $.ajax({
        url: '/ajax-reference-category',
        type: 'GET',
        data: { category_id: $(this).data('reference-category'), lang_id: $('html').attr('lang') }
    }).done(function (result) {
        var items = JSON.parse(result);
        var menu = $('#ajax-nav-top');
        var heading = menu.find('h1');
        $('#ajax-home').show();

        if (heading.length) {
            heading.show().text(title);
        } else {
            menu.find('ul').before('<h1 class="menu-container__title menu-container__title--mobile">' + title + '</h1>');
        }

        menu.hide();
        menu.find('ul li').remove();

        $.each(items, function (index, elem) {
            menu.find('ul').append('<li><a href="' + elem.url + '">' + elem.title + '</a></li>');
        });

        menu.fadeIn(250);
    });
});

$(function() {
    if ($('.carousel').length) {
        $('.carousel').flickity({
            accessibility: true,
            adaptiveHeight: true,
            autoPlay: 6000,
            pauseAutoPlayOnHover: false,
            cellAlign: 'center',
            cellSelector: undefined,
            contain: true,
            draggable: true,
            dragThreshold: 3,
            freeScroll: false,
            friction: 0.3,
            groupCells: false,
            initialIndex: 0,
            lazyLoad: true,
            percentPosition: true,
            prevNextButtons: true,
            pageDots: false,
            resize: true,
            rightToLeft: false,
            setGallerySize: true,
            watchCSS: false,
            wrapAround: true,
            arrowShape: {
              x0: 25,
              x1: 60, y1: 50,
              x2: 75, y2: 50,
              x3: 40
            }
        });
    }

    if ($('.alert').length) {
        $('html, body').animate({ scrollTop: $('.alert').offset().top }, 1000);
    }
});

$(document).on('click', '.menu-container__title--mobile', function (e) {
    e.preventDefault();
    if ($(window).width() < 767) {
        $('.menu-container ul').slideToggle(250);
    }
});

$(window).bind('load resize', function() {
    if ($(window).width() < 767) {
        $('.menu-container h1').removeClass('.menu-container__title--mobile');
    } else {
        $('.menu-container h1').addClass('.menu-container__title--mobile');
    }
});
